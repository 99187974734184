import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "storefront/GlobalState";
import { Id } from "storefront/lib/Id";
import Heart from "storefront/components/Icons/Heart";
import { MyGrailsHeaderTooltip } from "storefront/components/Onboarding/MyGrails";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import TrackableMenuItem from "../TrackableMenuItem";
import styles from "./MyGrailsHeart.module.scss";

const userIdSelector = (state: GlobalState): Id => state.session.currentUser.id;

const MyGrailsHeart = () => {
  const { track } = useAnalytics();
  const userId = useSelector(userIdSelector);

  const handleClick = () =>
    track(navigationClicked("Favorites", "global_header"));

  return (
    <div className="-mygrails">
      <TrackableMenuItem
        href={`/mygrails/${userId}`}
        label="Favorites"
        onClick={handleClick}
      >
        <div className="-heart-wrapper">
          <Heart isFull />
        </div>
      </TrackableMenuItem>
      <MyGrailsHeaderTooltip className={styles.tooltip} />
    </div>
  );
};

export default MyGrailsHeart;
