import { isEmpty } from "lodash/fp";
import { Listing } from "storefront/Listing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { MoneyV2, usdV2 } from "storefront/Money";

/**
 * @name originalPrice
 * @memberof Listing
 * @description Returns the price of the listing when it was first created. With the listing's
 * current price, it is useful for showing how much the price has dropped over the listing's
 * lifetime.
 */
const originalPrice = (
  listing:
    | Listing
    | AlgoliaMyItemsListing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | AlgoliaListing,
): MoneyV2<"USD"> =>
  isEmpty(listing.priceDrops)
    ? usdV2(listing.price)
    : usdV2(listing.priceDrops[0]);

export default originalPrice;
