import type { Currency, Money, MoneyV2 } from "./index";
import "./index";

/**
 * @name equals
 * @memberof Money
 * @deprecated Use equalsV2
 */
const equals =
  <C extends Currency>(a: Money<C>): ((b: Money<C>) => boolean) =>
  (b) => {
    if (a.currency !== b.currency)
      throw new Error(`Currency Mismatch: ${a.currency}, ${b.currency}`);
    return a.amount === b.amount;
  };

/**
 * @name equalsV2
 * @memberof Money
 */
export const equalsV2 =
  <C extends Currency>(a: MoneyV2<C>): ((b: MoneyV2<C>) => boolean) =>
  (b) => {
    if (a.currency !== b.currency)
      throw new Error(`Currency Mismatch: ${a.currency}, ${b.currency}`);

    if (Number.isNaN(Number(a.amount)) || Number.isNaN(Number(b.amount)))
      throw new Error(`Invalid number passed: ${a.amount}, ${b.amount}`);

    return Number(a.amount) === Number(b.amount);
  };

export default equals;
