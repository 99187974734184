import React from "react";
import cn from "classnames";
import { Avatar, AvatarImage, AvatarFallback } from "storefront/ui/avatar";
import imageSrc from "storefront/components/Image/imageSrc/index";
import { User } from "storefront/components/Avatar/helpers";

import styles from "./AvatarWithFallback.module.scss";

type Size = "small" | "medium" | "large" | "fullWidth";

type AvatarProps = {
  user: User;
  size?: Size;
  className?: string;
};

const AvatarWithFallback = ({
  user: { avatarUrl, username },
  size,
  className,
}: AvatarProps) => (
  <Avatar size={size} className={cn(styles.avatar, className)}>
    <AvatarImage
      src={
        avatarUrl ? imageSrc(avatarUrl, { height: 70, width: 70 }) : undefined
      }
    />
    <AvatarFallback
      className={cn({
        [styles.smallFallback]: size === "small",
        [styles.mediumFallback]: !size || size === "medium",
        [styles.largeFallback]: size === "large",
      })}
    >
      {username.slice(0, 2).toUpperCase()}
    </AvatarFallback>
  </Avatar>
);

export default AvatarWithFallback;
