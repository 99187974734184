import React, { useRef } from "react";

import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import { WOMENSWEAR, MENSWEAR } from "storefront/Department";
import Help from "storefront/components/Page/Header/SlideOutMenu/Items/Help";
import Department from "storefront/components/Page/Header/SlideOutMenu/Items/Department";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";

import Item from "./Item";

export type Props = {
  expandedSection: string | null;
  setExpandedSection: (value: React.SetStateAction<string | null>) => void;
  isSignedIn: boolean;
};

const SlideOutMenuItems = ({
  isSignedIn,
  expandedSection,
  setExpandedSection,
}: Props) => {
  const { track } = useAnalytics();
  const menswearDepartmentItemRef = useRef<HTMLHeadingElement>(null);

  const trackNav = (name: string) => {
    track(navigationClicked(name, "global_header"));
  };

  return (
    <>
      <Item
        label="Designers"
        href="/designers"
        from="sub_header"
        onClick={() => trackNav("Designers")}
      />
      <SuspenseWithFragment>
        <Department
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
          department={MENSWEAR}
          menswearDepartmentItemRef={menswearDepartmentItemRef}
        />
        <Department
          expandedSection={expandedSection}
          setExpandedSection={setExpandedSection}
          department={WOMENSWEAR}
          menswearDepartmentItemRef={menswearDepartmentItemRef}
        />
      </SuspenseWithFragment>
      <Item
        label="Sneakers"
        href="/categories/sneakers"
        from="sub_header"
        onClick={() => trackNav("Sneakers")}
      />
      <Item
        label="Sell"
        href="/sell/for-sale"
        onClick={() => trackNav("Sell")}
      />
      {isSignedIn ? (
        <Item
          label="For You"
          href="/foryou"
          onClick={() => trackNav("For You")}
        />
      ) : null}
      <Item label="Shop" href="/shop" onClick={() => trackNav("Shop")} />
      <Item
        label="Staff Picks"
        href="/shop/staff-picks"
        from="sub_header"
        onClick={() => trackNav("Staff Picks")}
      />
      <Item
        label="Collections"
        href="/collections"
        onClick={() => trackNav("Collections")}
      />
      <Item
        label="Dry Clean Only"
        href="/drycleanonly"
        onClick={() => trackNav("Dry Clean Only")}
      />
      <Help />
    </>
  );
};

export default SlideOutMenuItems;
