import * as React from "react";
import {
  Root as NavigationMenu,
  List as NavigationMenuList,
  Item as NavigationMenuItem,
  Trigger as NavigationMenuTrigger,
  Content as NavigationMenuContent,
  Viewport as NavigationMenuViewport,
  Link as NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import cn from "classnames";

import ToggleCaret from "storefront/components/Icons/ToggleCaret";
import { Typography } from "storefront/ui/typography";
import { MENSWEAR, WOMENSWEAR } from "storefront/Department";

import DesignersDropdown from "./components/DesignersDropdown";
import CategoryDropdown from "./components/CategoryDropdown";

import styles from "./MerchandisingMenu.module.scss";

const Designers = () => {
  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
        <Typography variant="title-sm">DESIGNERS</Typography>{" "}
        <ToggleCaret className={styles.caret} aria-hidden="true" />
      </NavigationMenuTrigger>
      <NavigationMenuContent className={styles.content}>
        <DesignersDropdown />
      </NavigationMenuContent>
    </NavigationMenuItem>
  );
};

const MerchandisingMenu = () => {
  return (
    <NavigationMenu className={styles.root}>
      <NavigationMenuList className={styles.list}>
        <Designers />
        <NavigationMenuItem>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">MENSWEAR</Typography>{" "}
            <ToggleCaret className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent className={styles.content}>
            <CategoryDropdown department={MENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger className={cn(styles.item, styles.trigger)}>
            <Typography variant="title-sm">WOMENSWEAR</Typography>{" "}
            <ToggleCaret className={styles.caret} aria-hidden="true" />
          </NavigationMenuTrigger>
          <NavigationMenuContent className={styles.content}>
            <CategoryDropdown department={WOMENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/sneakers">
            <Typography variant="title-sm">SNEAKERS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/shop/staff-picks">
            <Typography variant="title-sm">STAFF PICKS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/collections">
            <Typography variant="title-sm">COLLECTIONS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink className={styles.item} href="/drycleanonly">
            <Typography variant="title-sm">EDITORIAL</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
      <div className={styles.viewportContainer}>
        <NavigationMenuViewport className={styles.viewport} />
      </div>
    </NavigationMenu>
  );
};

export default MerchandisingMenu;
