import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "storefront/ui/utils";
import { typographyVariants } from "storefront/ui/typography";

const avatarVariants = cva(
  "relative flex rounded-full w-10 h-10 overflow-hidden shrink-0",
  {
    variants: {
      size: {
        small: "w-[24px] h-[24px]",
        medium: "w-[36px] h-[36px]",
        large: "w-[48px] h-[48px]",
        fullWidth: "w-full h-full",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);

export interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, key, size, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    key={String(key)}
    className={cn(avatarVariants({ size }), className)}
    {...props}
  />
));

Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, key, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    key={String(key)}
    className={cn("aspect-square h-full w-full object-cover", className)}
    {...props}
  />
));

AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, key, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    key={String(key)}
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-primary text-white",
      typographyVariants({ variant: "sub-head" }),
      className,
    )}
    role="img"
    {...props}
  />
));

AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback };
