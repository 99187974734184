import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const MessagesOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 13L2.5 13.5H17L18.5 12V3L17 1.5H3L1.5 3V13ZM0 19V2L2 0H18L20 2V13L18 15H2.5L0 19Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default MessagesOutlineIcon;
