import React from "react";

import { Icon } from "storefront/Icon";

const CircleCross = ({
  width,
  height,
  color = "dark",
}: Pick<Icon, "width" | "height"> & { color?: "light" | "dark" }) => (
  <svg
    width={width || "24"}
    height={height || "24"}
    viewBox={`0 0 ${width || "24"} ${height || "24"}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Navigation/X Circle">
      <circle
        id="CIRCLE"
        cx="12"
        cy="12"
        r="10.5"
        fill={color === "light" ? "#E1E1E1" : "#343434"}
      />
      <path
        id="VECTOR"
        d="M10.9394 11.9991L6.96973 15.9688L8.03039 17.0294L12.0001 13.0597L15.9697 17.0294L17.0304 15.9688L13.0607 11.9991L17.0304 8.02941L15.9697 6.96875L12.0001 10.9384L8.03039 6.96875L6.96973 8.02941L10.9394 11.9991Z"
        fill={color === "light" ? "#343434" : "#E1E1E1"}
      />
    </g>
  </svg>
);

export default CircleCross;
