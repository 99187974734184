import React from "react";
import cn from "classnames";
import * as M from "storefront/Money";
import css from "./Money.module.scss";

type Props<C extends M.Currency> = {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  money: M.MoneyV2<C>;
  label?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const symbol: Record<M.Currency, string> = {
  USD: "$",
};

const Money = <C extends M.Currency>({
  as: As = "span",
  className,
  money,
  label,
  ...props
}: Props<C>) => (
  <As className={cn(css.root, className)} {...props}>
    {`${symbol[money.currency]}${money.amount}`}
    {label ? <span className={css.label}>{label}</span> : null}
  </As>
);

export default Money;
