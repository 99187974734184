import type { Config } from "storefront/Config";
import type { Filters } from "storefront/Filters";
import type { FittingRoom } from "storefront/FittingRoom";
import type { Listings } from "storefront/Listings";
import type { SellForm } from "storefront/SellForm";
import type { InternalTools } from "storefront/InternalTools";
import { empty as emptyCapsules } from "storefront/Capsules";
import type { Capsules } from "storefront/Capsules";
import articles from "./articles";
import type { Articles } from "../types/State/Articles";
import assets from "./assets";
import type { Assets } from "./assets";
import config from "./config";
import conversations from "./conversations";
import type { Conversations } from "./conversations";
import filters from "./filters";
import fittingRoom from "./fittingRoom";
import homepage from "./homepage";
import type { Homepage } from "./homepage";
import internalTools from "./internalTools";
import listings from "./listings";
import modules from "./modules";
import type { Modules } from "./modules";
import sellForm from "./sellForm";
import session from "./session";
import type { Session } from "./session";

export type State = {
  articles: Articles;
  assets: Assets;
  capsules: Capsules;
  config: Config;
  conversations: Conversations;
  filters: Filters;
  fittingRoom: FittingRoom;
  homepage: Homepage;
  internalTools: InternalTools;
  listings: Listings;
  modules: Modules;
  sellForm: SellForm;
  session: Session;
};

const createInitialState = (): State => ({
  articles: articles(window),
  assets: assets(window),
  capsules: emptyCapsules,
  config: config(window),
  conversations: conversations(window),
  filters: filters(window),
  fittingRoom: fittingRoom(window),
  homepage: homepage(window),
  internalTools: internalTools(window),
  listings: listings(window),
  modules,
  sellForm: sellForm(window),
  session: session(),
});

export default createInitialState;
