import "./CategoriesNavigation.scss";
import React, { Suspense, lazy } from "react";

import { StaffPicksLink, CollectionsLink } from "./SimpleLinks";
import DesignersDropdown from "./DesignersDropdown";
import BrowseByCategoryDropdown from "./BrowseByCategoryDropdown";

const SneakersLink = lazy(() =>
  import(
    /* webpackChunkName: "SneakersLink" */
    "./SimpleLinks"
  ).then((module) => ({
    default: module.SneakersLink,
  })),
);

const CategoriesNavigation = () => (
  <nav className="Page-Header-CategoriesNavigation">
    <div className="-outer">
      <DesignersDropdown />
      <BrowseByCategoryDropdown department="menswear" />
      <BrowseByCategoryDropdown department="womenswear" />
      <Suspense fallback={<></>}>
        <SneakersLink />
      </Suspense>
      <StaffPicksLink />
      <CollectionsLink />
    </div>
  </nav>
);

export default CategoriesNavigation;
