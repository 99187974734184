import React, { useContext } from "react";
import cn from "classnames";

import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import buttonClicked from "storefront/Analytics/EventCreators/buttonClicked";
import { Context as AnalyticsContext } from "../../../../hooks/useAnalytics";

import styles from "./SimpleLinks.module.scss";

type SimpleLinkProps = {
  title: string;
  href: string;
  trackingPropertyName: string;
  targetBlank?: boolean;
  className?: string;
};

const SimpleLink = ({
  title,
  href,
  trackingPropertyName,
  targetBlank = false,
  className,
}: SimpleLinkProps) => {
  const { track } = useContext(AnalyticsContext);

  const onClick = () => {
    track(buttonClicked(trackingPropertyName, "sub_header"));
    track(navigationClicked(trackingPropertyName, "sub_header"));
  };

  return (
    <a
      className={cn(styles.link, className)}
      href={href}
      onClick={onClick}
      rel={targetBlank ? "noreferrer noopener" : undefined}
      target={targetBlank ? "_blank" : undefined}
    >
      {title}
    </a>
  );
};

export const StaffPicksLink = () => (
  <SimpleLink
    title="Staff Picks"
    href="/shop/staff-picks"
    trackingPropertyName="staff_picks"
  />
);
export const SneakersLink = () => (
  <SimpleLink
    title="Sneakers"
    href="/categories/sneakers"
    trackingPropertyName="sneakers"
  />
);
export const CollectionsLink = () => (
  <SimpleLink
    title="Collections"
    href="/collections"
    trackingPropertyName="collections"
  />
);
