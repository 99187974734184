import React from "react";
import cn from "classnames";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaMyItemsListing } from "storefront/Listing/AlgoliaMyItemsListing";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import currentPrice from "storefront/Listing/currentPrice";
import soldPrice from "storefront/Listing/soldPrice";
import originalPrice from "storefront/Listing/originalPrice";
import { Offer, isExpired } from "storefront/Offer";
import { usdV2 } from "storefront/Money";
import { equalsV2 } from "storefront/Money/equals";
import percentDifference from "storefront/Money/percentDifference";
import SmallTitle from "storefront/components/SmallTitle";
import Money from "./Money";
import styles from "./Price.module.scss";

type Props = {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  listing:
    | AlgoliaMyItemsListing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | AlgoliaInstantSearchListing
    | AlgoliaListing;
  offer?: Offer | null;
  size?: "small" | "large";
  showPercent?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;

function Price({
  as: Component = "div",
  className,
  offer,
  listing,
  size,
  showPercent = true,
}: Props) {
  const isSold = "sold" in listing ? listing.sold : false;
  const sold = "soldPrice" in listing ? soldPrice(listing) : null;

  const hasOffer = !!offer && !isExpired(offer);
  const current = currentPrice(listing);
  const original = originalPrice(listing);
  const percentOff = percentDifference(current, original);

  const isEqual = equalsV2(current)(original);

  return (
    <Component
      className={cn(styles.root, className, {
        [styles.small]: size === "small",
        [styles.large]: size === "large",
      })}
    >
      {isSold && sold ? (
        <div data-testid="Current">
          <SmallTitle as="span" className={styles.soldLabel}>
            Sold For
          </SmallTitle>{" "}
          <Money money={sold} />
        </div>
      ) : null}

      {hasOffer && offer ? (
        <>
          <Money
            className={styles.offer}
            money={usdV2(offer.amount)}
            label=" (Offer Price)"
            data-testid="Current"
          />

          <Money
            money={current}
            data-testid="Last"
            className={styles.original}
          />
        </>
      ) : null}

      {!hasOffer && !isSold ? (
        <Money
          className={cn({ [styles.onSale]: !isEqual && !!original })}
          money={current}
          data-testid="Current"
        />
      ) : null}

      {!isEqual && original && (size !== "small" || !isSold) ? (
        <Money
          className={cn(styles.original)}
          money={original}
          data-testid="Original"
        />
      ) : null}

      {showPercent && !isSold && !isEqual && percentOff ? (
        <span
          className={cn(styles.percentOff)}
          data-testid="PercentOff"
        >{`${percentOff}% off`}</span>
      ) : null}
    </Component>
  );
}

export default Price;
