import React from "react";

type RecentSearch = {
  type: "designer" | "query";
  label: string;
  value: string;
};

const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = React.useState<RecentSearch[]>(
    [],
  );

  React.useEffect(() => {
    const searches = window.localStorage.getItem("recentSearches");
    if (searches) {
      setRecentSearches(JSON.parse(searches));
    }
  }, []);

  const addSearch = React.useCallback(
    (search: RecentSearch) => {
      const duplicate = recentSearches.find((s) => s.label === search.label);
      if (duplicate) {
        return;
      }
      const newSearches = [search, ...recentSearches].slice(0, 5);
      setRecentSearches(newSearches);
      localStorage.setItem("recentSearches", JSON.stringify(newSearches));
    },
    [recentSearches],
  );

  const deleteSearch = React.useCallback(
    (search: RecentSearch) => {
      const newSearches = recentSearches.filter(
        (s) => s.label !== search.label,
      );
      setRecentSearches(newSearches);
      window.localStorage.setItem(
        "recentSearches",
        JSON.stringify(newSearches),
      );
    },
    [recentSearches],
  );

  return {
    recentSearches,
    addSearch,
    deleteSearch,
  };
};

export default useRecentSearches;
