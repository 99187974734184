import React from "react";
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import classNames from "classnames";

import { Popover, PopoverAnchor, PopoverContent } from "storefront/ui/popover";
import X from "storefront/components/Icons/Navigation/X";
import { SearchIcon } from "storefront/ui/icons";
import { Input } from "storefront/ui/input";
import { cn } from "storefront/ui/utils";
import { Typography } from "storefront/ui/typography";
import usePublicConfig from "storefront/hooks/usePublicConfig";
import findAlgoliaIndexName from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import searchesCreate from "storefront/GrailedAPI/v1/Searches/create";
import titleCase from "storefront/lib/String/titleCase";
import {
  Dialog,
  DialogTrigger,
  DialogOverlay,
  DialogContent,
  DialogPortal,
} from "storefront/ui/dialog";
import { Button } from "storefront/ui/button";

import useHeaderSearch from "./useHeaderSearch";
import useRecentSearches from "./useRecentSearches";

import styles from "./Search.module.scss";

function assertIsNode(e: EventTarget | null): asserts e is Node {
  if (!e || !("nodeType" in e)) {
    throw new Error("Node expected");
  }
}

const ResultsSection = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.resultSection}>{children}</div>;
};

const SearchInput = ({ onClose }: { onClose?: () => void }) => {
  const comboboxRef = React.useRef<HTMLInputElement>(null);
  const listboxRef = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const config = usePublicConfig();

  const createSearchAndRedirect = React.useCallback(
    async (query: string) => {
      const algoliaIndex =
        findAlgoliaIndexName("listings", "default")(config) || "";

      const params = {
        indexName: algoliaIndex,
        query,
      };

      const { data } = await searchesCreate(params);

      window.location.href = `/shop/${data.uuid}`;
    },
    [config],
  );

  const {
    query,
    setQuery,
    results: { querySuggestions, designers },
  } = useHeaderSearch();

  const { recentSearches, addSearch, deleteSearch } = useRecentSearches();

  const handleSearchIconClick = () => {
    comboboxRef.current?.focus();
  };

  const handleClearIconClick = () => {
    comboboxRef.current?.blur();
  };

  const onInputFocus = () => {
    setOpen(true);
  };

  const onInputBlur = () => {
    onClose?.();
    setOpen(false);
  };

  const showRecentSearches = query.length === 0 && recentSearches.length > 0;

  const showResults =
    open &&
    (showRecentSearches || querySuggestions.length > 0 || designers.length > 0);

  const handleSubmit = React.useCallback(() => {
    if (query.length > 0) {
      createSearchAndRedirect(query);
      addSearch({
        type: "query",
        label: query,
        value: query,
      });
    }
  }, [query, createSearchAndRedirect, addSearch]);

  const handleSearchButtonClick = () => {
    onClose?.();
    setOpen(false);
    if (query.length > 0) {
      createSearchAndRedirect(query);
      addSearch({
        type: "query",
        label: query,
        value: query,
      });
      return;
    }

    window.location.href = "/shop";
  };

  return (
    <Popover open={showResults} onOpenChange={setOpen}>
      <ComboboxProvider
        open={showResults}
        setOpen={setOpen}
        value={query}
        setValue={setQuery}
      >
        <div className={styles.wrapper} ref={wrapperRef}>
          {!open ? (
            <button
              type="button"
              className={cn(styles.search, styles.button)}
              aria-label="Open search"
              onClick={handleSearchIconClick}
              tabIndex={-1}
            >
              <SearchIcon />
            </button>
          ) : (
            <button
              type="button"
              className={cn(styles.clear, styles.button)}
              aria-label="Clear search"
              onClick={handleClearIconClick}
              tabIndex={-1}
            >
              <X />
            </button>
          )}
          <PopoverAnchor asChild>
            <form onSubmit={handleSubmit} ref={formRef}>
              <Combobox
                ref={comboboxRef}
                render={
                  <Input
                    className={styles.input}
                    placeholder="Search for anything"
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                  />
                }
              />
              <Button
                className={styles.searchButton}
                size="small"
                variant="secondary"
                onClick={handleSearchButtonClick}
                type="button"
                tabIndex={-1}
              >
                Search
              </Button>
            </form>
          </PopoverAnchor>
        </div>
        <PopoverContent
          asChild
          sideOffset={8}
          onOpenAutoFocus={(event) => event.preventDefault()}
          onInteractOutside={(event) => {
            const { target } = event;
            const isCombobox = target === comboboxRef.current;

            // Narrowing type to Node
            assertIsNode(target);
            const inListbox = target && listboxRef.current?.contains(target);
            if (isCombobox || inListbox) {
              event.preventDefault();
            }
          }}
        >
          <ComboboxList
            ref={listboxRef}
            role="listbox"
            className={styles.results}
          >
            {showRecentSearches ? (
              <ResultsSection>
                <Typography
                  variant="sub-head"
                  className={styles.resultSectionHeader}
                >
                  Recent Searches
                </Typography>
                {recentSearches.map((search) => (
                  <ComboboxItem
                    className={styles.result}
                    focusOnHover
                    value={search.label}
                    onClick={() => {
                      if (search.type === "query") {
                        createSearchAndRedirect(search.value);
                      }
                      if (search.type === "designer") {
                        window.location.href = `/designers/${search.value}`;
                      }
                    }}
                    onKeyDown={(event) => {
                      // If event is backspace, delete the search
                      if (event.key === "Backspace") {
                        deleteSearch(search);
                      }
                    }}
                  >
                    <Typography variant="callout">{search.label}</Typography>
                    <button
                      type="button"
                      className={classNames(styles.button, styles.delete)}
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteSearch(search);
                      }}
                    >
                      <X />
                    </button>
                  </ComboboxItem>
                ))}
              </ResultsSection>
            ) : null}
            {!showRecentSearches && querySuggestions.length > 0 ? (
              <ResultsSection>
                <Typography
                  variant="sub-head"
                  className={styles.resultSectionHeader}
                >
                  Popular Searches
                </Typography>
                {querySuggestions.map((suggestion) => (
                  <ComboboxItem
                    focusOnHover
                    value={titleCase(suggestion.query)}
                    onClick={() => {
                      addSearch({
                        type: "query",
                        label: titleCase(suggestion.query),
                        value: suggestion.query,
                      });
                      createSearchAndRedirect(suggestion.query);
                    }}
                    className={styles.result}
                  >
                    <Typography variant="callout">
                      {titleCase(suggestion.query)}
                    </Typography>
                  </ComboboxItem>
                ))}
              </ResultsSection>
            ) : null}
            {!showRecentSearches && designers.length > 0 ? (
              <ResultsSection>
                <Typography
                  variant="sub-head"
                  className={styles.resultSectionHeader}
                >
                  Designers
                </Typography>
                {designers.map((designer) => (
                  <ComboboxItem
                    focusOnHover
                    value={designer.name}
                    onClick={() => {
                      addSearch({
                        type: "designer",
                        label: designer.name,
                        value: designer.slug,
                      });
                      window.location.href = `/designers/${designer.slug}`;
                    }}
                    className={styles.result}
                  >
                    <Typography variant="callout">{designer.name}</Typography>
                  </ComboboxItem>
                ))}
              </ResultsSection>
            ) : null}
          </ComboboxList>
        </PopoverContent>
      </ComboboxProvider>
    </Popover>
  );
};

const DesktopSearch = () => {
  return (
    <div className={styles.desktopSearch}>
      <SearchInput />
    </div>
  );
};

const MobileSearch = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={styles.mobileSearch}>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className={styles.button}>
          <SearchIcon />
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay />
          <DialogContent className={styles.mobileContent}>
            <SearchInput onClose={() => setOpen(false)} />
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </div>
  );
};

const Search = () => {
  return (
    <>
      <DesktopSearch />
      <MobileSearch />
    </>
  );
};

export default Search;
